<template>
    <div class="structuralModification">
        <van-nav-bar title="房间结构"  left-arrow @click-left="leftReturn" />
        <div class="potential">
            <van-row class="info" type="flex">
                <van-col span="24" > 
                    <p style="float:right;color:#ff5d3b;padding-right:10px;font-size:15px" @click="addStructure">+添加结构<span></span></p>
                </van-col>
            </van-row>
        </div>   
                          
        <van-collapse v-model="roomNameData">
            <van-collapse-item v-for="item,index in roomData" :key="index" :name="item.id"  :title='item.roomName'   class="deploy-setting" style="border-bottom-right-radius: 10px">  
               <div class="contract" v-for="little,littleIndex in roomData[index].child" :key="little.id">
                        <div class="title" >{{little.regionalLocationName}}</div>
                    <div class="potential">
                        <div class="panel">
                            <van-row class="info">
                                <van-col span="24">
                                    <p>位置：<span>{{ little.areaName }}</span></p>
                                </van-col>
                                <van-col span="24">
                                    <p>水表位置：<span>{{ little.waterMeterPosition }}</span></p>
                                </van-col>
                                <van-col span="24">
                                    <p>电表位置：<span>{{ little.meterLocation }}</span></p>
                                </van-col>
                                <van-col span="24">
                                    <p>煤气表位置：<span>{{ little.gasMeterPosition }}</span></p>
                                </van-col>
                            </van-row>
                        </div>
                    </div>
                        <div class="bottom">                           
                            <div @click="addStructure(little)" class="bottom-changeLease">修改</div>
                            <div @click="deleteStructrate(little,littleIndex)" class="bottom-property" style="float:left">删除</div>                          
                        </div>
               </div>
            </van-collapse-item>
        </van-collapse>



        <van-popup class="van-popup--bottom" v-model="addModalShow" position="bottom"> 
            <div  class="part-inputpart-row" @click="clickRoom">
                <div :class="addformValidate.roomName ? 'content-have' : 'content-none'"></div>
                <span class="part-inputpart-row-header">位置：</span>
                <span class="content-divide">|</span>
                <span class='part-inputpart-row-normaltext'>{{ addformValidate.roomName }}</span>
                <img class="part-inputpart-row-right" :class="roomShow? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'" src="../../assets/images/triangle.png"/>
            </div>
            <div  class="part-inputpart-row" @click="clickRegion">
                <div :class="addformValidate.regionalLocationName ? 'content-have' : 'content-none'"></div>
                <span class="part-inputpart-row-header">区域：</span>
                <span class="content-divide">|</span>
                <span class='part-inputpart-row-normaltext'>{{ addformValidate.regionalLocationName }}</span>
                <img class="part-inputpart-row-right" :class="regionShow? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'" src="../../assets/images/triangle.png"/>
            </div>
          <div class="part-inputpart-row">
              <div :class="addformValidate.waterMeterPosition ? 'content-have' : 'content-none'"></div>
              <span class="part-inputpart-row-header">水表位置：</span>
              <span class="content-divide">|</span>
              <div class="nopadding">
                <van-field class="nopadding" v-model="addformValidate.waterMeterPosition" placeholder="水表位置" ></van-field>
              </div>
          </div>  
          <div id="paymentMethodPanel" class="part-inputpart-row">
            <span :class="addformValidate.meterLocation ? 'content-have' : 'content-none'"></span>
            <span class="part-inputpart-row-header">电表位置：</span>
            <span class="content-divide">|</span>
            <div class="nopadding">
                <van-field class="nopadding" v-model="addformValidate.meterLocation" placeholder="电表位置" ></van-field>
              </div>
          </div>   
          <div class="part-inputpart-row">
              <div :class="addformValidate.gasMeterPosition ? 'content-have' : 'content-none'"></div>
              <span class="part-inputpart-row-header">煤气表位置：</span>
              <span class="content-divide">|</span>
              <div class="nopadding">
                <van-field class="nopadding" v-model="addformValidate.gasMeterPosition" placeholder="煤气表位置" ></van-field>
              </div>
          </div>                                      
            <van-button class="saveButton_Enable" :disabled="enable"
                    @click="save">保存
            </van-button>                                   
        </van-popup>  


        <van-popup v-model="roomShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="roomName"
              :columns="roomData"
              @cancel="roomShow = false"
              @confirm="confirmRoom"
            />
        </van-popup>

        <van-popup v-model="regionShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="dictionaryTitle"
              :columns="regionList"
              @cancel="regionShow = false"
              @confirm="confirmRegion"
            />
        </van-popup>
    </div>
</template>

<script>
import {
		NavBar,
		Picker,
		List,
		PullRefresh,
		Toast,
		Popup,
        Row,
        Col,Dialog,Button,Collapse,
        CollapseItem,
        Uploader,
        Field,
	} from "vant";
    Vue.use(Field); 
	Vue.use(Picker);
	Vue.use(List);
	Vue.use(PullRefresh);
	Vue.use(Popup);
	Vue.use(PullRefresh);
    import {
        queryHouseArea,
        queryRoom,
        findHouseArea,
        addOrupdateStructure,
        deleteSetHouseStructure,
        getQueryBaseData,
	} from "../../getData/getData";
	import {
		responseUtil,
         getStaffId,
	} from "../../libs/rongxunUtil";
	import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import dialMobile from '../../components/rongxun/rx-dialMobile/dialMobile'
    import eventBus from "../../components/rongxun/rx-navBar/envbus.js"
export default {
    components: {
        [NavBar.name]: NavBar,
        [List.name]: List,
        [PullRefresh.name]: PullRefresh,
        [Row.name]: Row,
        [Col.name]: Col,
        [Button.name]: Button,
        [Dialog.name]:Dialog,
        dialMobile,
        rxNavBar,
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
        [Uploader .name]:Uploader,

    },  
    data(){
        return{
            roomList:['1'],
            roomData:[],  //主列表
            roomNameData:[],  //标题名称列表
            allRegionList:[],  //所有区域列表
            addformValidate:{},  //新增表单
            addModalShow:false,  //填写时下拉表
            roomShow:false,  
            regionShow:false,
            roomList:[],
            regionList:[],
            enable:false
        }
    },
    created() {
    },
    mounted() {
        this.getHouseAreaList()  
        this.initDictionaryData()
    },
    methods:{
        leftReturn(){
            // eventBus.$emit('decorationList','decorationList')
            this.$router.go(-1);
        }, 
        //获取间和公区
        getQueryRoom(){
                let that=this
                let initData={}
                initData.sethouse_id=that.$route.query.setHouse_id
                initData.ar_id=that.$route.query.arid
                queryRoom(initData).then(function (response) {
                  responseUtil.dealResponse(that, response, () => {      
                    that.roomData=response.data.data.data
                    for(let i in that.roomData){
                        that.roomNameData.push(that.roomData[i].roomName)
                    }
                    that.addList()
                    // that.roomCountList=response.data.data.countList
                })
            })
        },
        getHouseAreaList(){
            let that = this
            let initData = {}
            initData.setHouse_id = this.$route.query.setHouse_id
            queryHouseArea(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    that.allRegionList = response.data.data.data
                    // let dataList = response.data.data
                    that.getQueryRoom()
                })
            })
        },
        addList(){
            console.log(this.roomData)
            console.log(this.allRegionList)
            for(let i in this.roomData){
                this.roomData[i].child=[]
                for(let j in this.allRegionList){
                    // console.log(this.roomData[i].id)
                    // console.log(this.allRegionList[j].roomHouse_id)
                    if(this.roomData[i].id == this.allRegionList[j].roomHouse_id){
                        this.roomData[i].child.push(this.allRegionList[j])
                    }
                }
            }
            console.log(this.roomData)
        },
        //添加修改房间结构
        addStructure(e){
            console.log(e)
            if(!e.id){
                this.addformValidate = {}
            }else{
                this.addformValidate = e
                // this.addformValidate.roomName = e.areaName
                
            }
            this.addModalShow=true
        },


        //选择房间
        clickRoom(){
            this.roomShow = !this.roomShow
        },
        //选择房间
        confirmRoom(item){
            console.log(item)
            this.addformValidate.roomHouse_id=item.id
            this.addformValidate.roomName=item.roomName
            this.roomShow=false
        },
            
        //选择区域
        clickRegion(){
            this.regionShow = !this.regionShow
        },
        //查询字典项
        initDictionaryData: function () {
            var that = this
            let initData = {}
            initData.user_id = getStaffId()
            initData.dbName = ['position']
            getQueryBaseData(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    that.regionList=response.data.data.position
                })
            })
        },
        confirmRegion(item){
            console.log(item)
            this.addformValidate.regionalLocation=item.id
            this.addformValidate.regionalLocationName=item.dictionaryTitle
            this.regionShow=false
        },


        saveAble(){
            if(this.addformValidate.roomHouse_id && this.addformValidate.regionalLocation){
                return true
            }else{
                return false
            }
        },
        //保存
        save(){
            if(!this.saveAble()){
                responseUtil.alertMsg(that,"必填项没填")   
                    return
            } 
            let that = this
            that.enable=true
            let initData = {}
            if(that.addformValidate.id){
                initData.id = that.addformValidate.id
            }
            initData.addStaff_id = getStaffId()
            initData.roomName =  that.addformValidate.roomName
            initData.roomHouse_id =  that.addformValidate.roomHouse_id?that.addformValidate.roomHouse_id:that.addformValidate.roomId
            initData.sethouse_id =  that.$route.query.setHouse_id
            initData.waterMeterPosition =  that.addformValidate.waterMeterPosition
            initData.gasMeterPosition =  that.addformValidate.gasMeterPosition
            initData.meterLocation =  that.addformValidate.meterLocation
            initData.regionalLocation =  that.addformValidate.regionalLocation
            console.log(that.addformValidate)
            addOrupdateStructure(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    that.getHouseAreaList()
                    that.addModalShow=false
                    that.enable=false

                })
            })
        },


        //删除
        deleteStructrate(item){
            console.log(item)
            Dialog.confirm({
                title: '友情提示',
                message: '确定删除吗？',
            })
                .then(() => {
                    // on confirm
                    let that = this
                    let initData = {}
                    initData.id = item.id
                    deleteSetHouseStructure(initData).then(response => {
                        responseUtil.dealResponse(that, response, () => {
                            if (response.data.code == 0) {
                                that.getHouseAreaList()
                                responseUtil.alertMsg(that, response.data.msg)
                            }
                        })
                    })
                })
                .catch(() => {
                // on cancel
                })
        }
    }
}
</script>

<style lang="less" scoped>
    @redius: 8px;
    .contract{
        position: relative;
        height: auto;
        background-color: white !important;
        border-radius: 8px;
        overflow: hidden;
        //margin-bottom:10px;
        padding:10px 10px 0 10px;
        margin-bottom:10px
    }   

    .title {
        font-weight: bolder;
        color: rgb(0, 0, 0);
        font-size: 15px;
        /*padding: 20px 15px 15px;*/
        /*border-bottom: 1px solid rgb(250, 250, 250);*/

        span {
            font-weight: normal;
            color: rgba(153, 153, 153);
        }
    }   

    .potential {
        width: 100%;
        /*background-color: #F8F8F8;*/

        box-sizing: border-box;
        overflow: hidden;
        color: #252525;


        .panel {
            width: 100%;
            /*background-color: rgb(255, 255, 255);*/
            
            position: relative;
            font-size: 14px;
            text-align: left;
            overflow: hidden;
            border-bottom: 1px solid #e0e0e0;


            // .title {
            //     // width: 315px;
            //     padding: 0 15px;
            //     font-weight: 900;
            //     color: rgb(0, 0, 0);
            //     font-size: 16px;
            //     padding-top: 20px;
            //     span {
            //         font-size: 14px;
            //         font-weight: normal;
            //         color: rgba(153, 153, 153, 1);
            //     }
            // }


            .info {
                padding: 15px;


                &.noPadding {
                    padding: 0 15px;
                }

                p {
                    margin: 4px 0;
                    font-weight: 900;

                    span {
                        font-size: 13px;
                        font-weight: normal;
                        color: rgb(110, 110, 110);
                    }
                }

            }

        }

    }  

    .bottom{
        //width: 330px;
        height: 60px;
        //padding-right: 15px;
        overflow: hidden;
    }
    .bottom-changeLease{
        float: right;
        width: 90px;
        height: 30px;
        margin-top: 15px;
        margin-right: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#7cc7ff,#3596fd);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    } 
    .bottom-property{
        float: right;
        width: 90px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        right: 15px;
        line-height: 30px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }  


    .part-inputpart-row {
       display: flex;
       flex-direction: row;
       border-radius: 8px;
       align-items: center;
       height: 45px;
       background-color: #ffffff;
       margin-top: 15px;
    }
    .part-inputpart-row-vedio {
        position:relative;
       display: flex;
       flex-direction: row;
       border-radius: 8px;
       align-items: center;
       background-color: #ffffff;
       margin-top: 30px ;
    }    
    .part-inputpart-row-header {
        margin-left: 26px;
        font-weight: 900; 
        font-size:12px; 
    } 

    .content-have {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;        
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;        
        background-color: #c7c7c7;
    }  

    .content-divide {
        font-size: 12px;
        color: #efefef;
        margin: 0 10px;
    }     
    .part-inputpart-row-graytext {
        width: 100px;
        color: #d8d8d8;
        flex: auto;
    }   
    .part-inputpart-row-normaltext {
        color: black;
        flex: auto;
        font-size:12px;
    }
    .part-inputpart-row-right {
        float: right;
        margin-right: 10px;
    }
    .part-inputpart-row-right-rightArrow {
        width: 8px;
    }

    .part-inputpart-row-right-downArrow {
        width: 9px;
    }

    .part-inputpart-row-right-upArrow {
        width: 9px;
        transform: rotateX(180deg);
    }

    .part-inputpart-row-right-timepick {
        width: 20px;
    }
    .saveButton_Enable {
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 25px 15px 80px;
        font-size: 18px;
        line-height: 1.22rem;
        text-align: center;

    }
</style>
<style>
   .structuralModification .van-cell{
        background-color:#f8f8f8 !important;  
    }  
    .structuralModification .van-collapse-item__content{
        background-color:#f8f8f8 !important;

    }
   .structuralModification .nopadding .van-cell{
        background-color:white !important;  
    }      
</style>